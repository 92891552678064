<template>
  <!-- <div class="content-spinner" v-if="start">
    <div class="lds-ripple"><div></div><div></div></div>
  </div> -->

  <div class="content-spinner" v-if="start">
    <div class="lds-loading">
      <img class="logo-loading" alt="{{ $t('_public._name_app') }}" src="../assets/img/loading.png">
    </div>
  </div>

</template>

<script>
export default {
  // name: 'Spinner',
  props: ["start"]
}
</script>


<style scoped>
.content-spinner {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, .8);
  z-index: 10002;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.lds-loading {
  display: inline-block;
  position: relative;
}
.logo-loading {
  width: 85px;
}
</style>
