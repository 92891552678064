import { ref, computed } from "vue";

export const isBtnSidebar = ref(true)
export const showBtnSidebar = () => (isBtnSidebar.value = true);
export const hideBtnSidebar = () => (isBtnSidebar.value = false);

export const isDisplayNavbar = ref(true)
export const showNavbar = () => (isDisplayNavbar.value = true);
export const hideNavbar = () => (isDisplayNavbar.value = false);

export const isDisplayFooter = ref(true)
export const showFooter = () => (isDisplayFooter.value = true);
export const hideFooter = () => (isDisplayFooter.value = false);

export const isDisplayTabs = ref(true)
export const showTabs = () => (isDisplayTabs.value = true);
export const hideTabs = () => (isDisplayTabs.value = false);

export const isDisplaySidebar = ref(false)
export const showSidebar = () => (isDisplaySidebar.value = true);
export const hideSidebar = () => (isDisplaySidebar.value = false);

export const collapsed = ref(false)
export const toggleSidebar = () => (collapsed.value = !collapsed.value)

export const SIDEBAR_WIDTH = 180
export const SIDEBAR_WIDTH_COLLAPSED = 0
export const sidebarWidth = computed(()=> `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`)