// function changLocalUser(te, val) {
//   if (localStorage.getItem("MY_USER_ALZ3EEM")) {
//     const _my_user = JSON.parse(localStorage.getItem("MY_USER_ALZ3EEM"));
//     _my_user[te] = val;
//   } 
// }


function getMyIdUser() {
  if (localStorage.getItem("MY_USER_ALZ3EEM")) {
    const _my_user = JSON.parse(localStorage.getItem("MY_USER_ALZ3EEM"));
    return _my_user['id'];
  } else  {
    return "20";
  }
}
function getMyTokenUser() {
  if (localStorage.getItem("MY_USER_ALZ3EEM")) {
    const _my_user = JSON.parse(localStorage.getItem("MY_USER_ALZ3EEM"));
    return _my_user['token'];
  } else  {
    return "visitor12345678910";
  }
}
function getMyCodeCurrencynUser() {
  if (localStorage.getItem("MY_USER_ALZ3EEM")) {
    const _my_user = JSON.parse(localStorage.getItem("MY_USER_ALZ3EEM"));
    return _my_user['code_currency'];
  } else  {
    return "$";
  }
}

function getMyUserLocale(te) {
  if (localStorage.getItem("MY_USER_ALZ3EEM")) {
    const _my_user = JSON.parse(localStorage.getItem("MY_USER_ALZ3EEM"));
    return _my_user[te]
  } else  {
    return "visitor"
  }

}

function isLogin() {
  if (localStorage.getItem("MY_USER_ALZ3EEM")) {
    return 'yes'
  } else  {
    return "no"
  }
}

function checkDisconnect() {
  if (localStorage.getItem("MY_USER_ALZ3EEM")) {
    const _my_user = JSON.parse(localStorage.getItem("MY_USER_ALZ3EEM"));
    return _my_user['isDisconnect'];
  } else  {
    return 'no';
  }
}

export const _changLocalUser = (te, val) => {
  if (localStorage.getItem("MY_USER_ALZ3EEM")) {
    const _my_user = JSON.parse(localStorage.getItem("MY_USER_ALZ3EEM"));
    _my_user[te] = val;
    localStorage.setItem("MY_USER_ALZ3EEM", JSON.stringify(_my_user));
    setTimeout(() => {
      location.reload();
    }, 500);
  } 
};

export default {
  isLogin: isLogin(),
  id_user: getMyIdUser(), //localStorage.getItem('MY_LANG_ALZ3EEM') || 'en',
  user: getMyUserLocale('user'),
  token: getMyTokenUser(),
  avatar: getMyUserLocale('avatar'),
  balance: getMyUserLocale('balance'),
  code_currency: getMyCodeCurrencynUser(),
  check_security_code: getMyUserLocale('check_security_code'),
  isDisconnect: checkDisconnect(),
}