<script>
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { collapsed } from './state'
// import { IonIcon } from '@ionic/vue';
// import { rocket } from 'ionicons/icons';


export default defineComponent ({
  components: { 
    // IonIcon
   },
  props: {
    to: { type: String, required: true},
    icon: { type: String, required: true}
  },
  setup(props) {
    const route = useRoute()
    const isActive = computed(()=>  route.path === props.to )

    return { isActive, collapsed }
  }
})
</script>
<template>
  <router-link :to="to" class="link" :class="{ active: isActive }">
    <ion-icon class="icon" :name="icon"></ion-icon>
    <transition name="fade">
      <span v-if="!collapsed"><slot /></span>
    </transition>
  </router-link>
  <!-- <router-link to="/about">About</router-link> -->
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.link {
  display: flex;
  align-items: center;

  cursor: pointer;
  position: relative;
  font-weight: 400;
  user-select: none;

  margin: 0.1em 0;
  padding: 1em .25em;
  border-radius: 0.25em;
  height: 1.5em;

  color: var(--color-text);
  text-decoration: none;
}
.link .icon {
  padding: 5px;
}
.link:hover {
  /* background-color: var(--sidebar-item-hover); */
  background-color: rgba(var(--public-rgb), 0.3);
}
.link.active {
  background-color: rgba(var(--public-rgb), 0.6);
}
</style>