<template>
  <div class="c--tabs" v-if="isDisplayTabs">

    <div class="navigation">
      <ul>


        <li class="list" :class="[rPath == '/wallet' ? 'active' : '']">
          <button class="btn-tabs" @click="goToPage('wallet')">
            <span class="icon"><ion-icon class="icons" name="wallet-outline"></ion-icon></span>
            <h5 class="text">wallet</h5>
          </button>
        </li>

        <li class="list" :class="[rPath == '/orders' ? 'active' : '']">
          <button class="btn-tabs" @click="goToPage('orders')">
            <span class="icon"><ion-icon class="icons" name="bag-outline"></ion-icon></span>
            <h5 class="text">orders</h5>
          </button>
        </li>

        <li class="list" :class="[rPath == '/' ? 'active' : '']">
          <button class="btn-tabs" @click="goToPage('')">
            <span class="icon"><ion-icon class="icons" name="home-outline"></ion-icon></span>
            <h5 class="text">Home</h5>
          </button>
        </li>

        <li class="list" :class="[rPath == '/favorite' ? 'active' : '']">
          <button class="btn-tabs" @click="goToPage('favorite')">
            <span class="icon"><ion-icon class="icons" name="heart-outline"></ion-icon></span>
            <h5 class="text">{{ $t('_sidebar._Favorite') }}</h5>
          </button>
        </li>

        <li class="list" :class="[rPath == '/notifications' ? 'active' : '']">
          <button class="btn-tabs" @click="goToPage('notifications')">
            <span class="icon"><ion-icon class="icons" name="notifications-outline"></ion-icon></span>
            <h5 class="text">{{ $t('_sidebar._Notifications') }}</h5>
          </button>
        </li>

        <div class="indicator"></div>
      </ul>

    </div>


  </div>
</template>

<script>
import { isDisplayTabs, hideTabs } from '@/components/sidebar/state';
import myUser from '../myUser';
import router from "@/router";
import { computed } from 'vue'
import { useRoute } from 'vue-router'


export default {
  name: 'TabsFooter',

  data() {

    return {
      isLogin: myUser.isLogin,
      rPath: this.$route.path,
    }
  },
  components: { 
    //SidebarLink 
  },
  methods: {
    async checkLogin(){
      console.log('route.path01', this.$route.path);
      console.log('rPath01', this.rPath);
      if (myUser.isLogin == 'yes') {
        this.getDataView();
        console.log("Tabs", myUser);
       
      } else {
        hideTabs();
      }
    },
    async getDataView(){
      console.log("getDataView");
    },
    async goToPage(page){
      router.push({ path: `/${page}` })
      this.rPath = `/${page}`;
      console.log('rPath02', this.rPath);
    },
  },
  mounted(){
    this.checkLogin()
  },
  setup() {
    const route = useRoute()
    // const rPath =  route.path;
    const isActive = computed(()=>  route.path )
    // const rPath =  route.path;
    console.log('isActive', isActive);
    // console.log('route.path', rPath);
    return { 
      isDisplayTabs
    }
  },
}
</script>

<style scoped>
.c--tabs {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  margin-top: 35px;
  padding-top: 11px;
  background: var(--bg-background);
}
.navigation {
  position: relative;
  height: 65px;
  padding: 15px;
  background: var(--box-background);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  /* margin: 20px; */
}
.navigation ul {
  display: flex;
  width: 350px;
}
.navigation ul li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
}
.navigation ul li .btn-tabs {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.navigation ul li .btn-tabs .icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: var(--color-text);
}
.navigation ul li.active .btn-tabs .icon {
  transform: translateY(-28px);
}
.navigation ul li .btn-tabs .text {
  position: absolute;
  color: var(--color-text);
  font-weight: 400;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}
.navigation ul li.active .btn-tabs .text {
  opacity: 1;
  transform: translateY(10px);
}
.navigation ul li.active .btn-tabs .icon {
  color: #fff;
}
.indicator {
  position: absolute;
  top: -50%;
  width: 70px;
  height: 70px;
  background: var(--public);
  border-radius: 50%;
  border: 6px solid var(--bg-background);
  transition: 0.5s;

}
.indicator::before {
  content: '';
  position: absolute;
  top: 46%;
  left: -20px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 0px -10px 0 0 var(--bg-background);
}
.indicator::after {
  content: '';
  position: absolute;
  top: 46%;
  right: -20px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-left-radius: 20px;
  box-shadow: 0px -10px 0 0 var(--bg-background);
}
.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(70px * 0));
}
.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(70px * 1));
}
.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(70px * 2));
}
.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(70px * 3));
}
.navigation ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(70px * 4));
}
.navigation ul li:nth-child(6).active ~ .indicator {
  transform: translateX(calc(70px * 5));
}


</style>
